import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useCommunityPolicies from '@aurora/shared-client/components/community/useCommunityPolicies';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { canAccessPageBuilder as checkCanAccessPageBuilder } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/icons';
import type { NodePageAndParams } from '@aurora/shared-client/routes/adminRoutes';
import { AdminPages } from '@aurora/shared-client/routes/adminRoutes';
import type { ManageContentPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useAdminUserRoutes from '@aurora/shared-client/routes/useAdminRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Category } from '@aurora/shared-generated/types/graphql-schema-types';
import type { CategoryPoliciesFragment } from '@aurora/shared-generated/types/graphql-types';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import endUserComponentRegistry from '../../../../features/endUserComponentRegistry';
import { CategoryAction } from '../../../../types/enums';
import { usePageEditorLink } from '../../../usePageEditorLink';
import useTranslation from '../../../useTranslation';
import localStyles from './CategoryActionMenu.module.pcss';

const log = getLog(module);

interface CategoryActionMenuItem {
  /**
   * Name of the action
   */
  name: CategoryAction;
  /**
   * Method to determine whether to display the action item
   * @return whether or not to display the action item.
   */
  policy?: () => boolean;
  /**
   * Event handler for the action taken
   */
  actionHandler: () => void;
}

interface Props {
  /**
   * the Category information object
   */
  category: Pick<Category, 'id'> & CategoryPoliciesFragment;
}

/**
 * Display Category Action Menu
 *
 * Is not displayed when user does not meet policy criteria to see the any of the menu items
 *
 * @author Guido Mininno
 */
const CategoryActionMenu: React.FC<React.PropsWithChildren<Props>> = ({ category }) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { router: endUserRouter } = useEndUserRoutes();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.CATEGORY_ACTION_MENU
  );
  const adminRouter = useAdminUserRoutes().router;
  const { isEditable: isPageEditable } = endUserComponentRegistry.getPageDescriptor(
    EndUserPages.CategoryPage
  );
  const { link: pageEditorLink } = usePageEditorLink();

  const {
    data: communityPoliciesData,
    loading: communityPoliciesLoading,
    error: communityPoliciesError
  } = useCommunityPolicies(module, {
    useCanAccessPageBuilder: true
  });

  if (textLoading || communityPoliciesLoading) {
    return null;
  }

  if (communityPoliciesError) {
    log.error(`Error fetching policy information: ${communityPoliciesError.message}`);
  }

  const canAccessPageBuilder: boolean = checkCanAccessPageBuilder(communityPoliciesData?.coreNode);

  /**
   * Checks for showing the Manage Content button based
   * on user permissions.
   */
  function getPolicyChecksForManageContent(): boolean {
    return (
      checkPolicy(category?.categoryPolicies.canManageArticleBlog) ||
      checkPolicy(category?.categoryPolicies.canManageArticleTkb)
    );
  }

  /**
   * Event handler for Manage Content in Category action
   */
  async function onManageCategoryContentHandler(): Promise<void> {
    await endUserRouter.pushRoute<ManageContentPageAndParams>(
      EndUserPages.ManageContentPage,
      null,
      {
        [EndUserQueryParams.SEARCH_FILTER_BY_LOCATION]: IdConverter.decodeId(tenant, category?.id)
      }
    );
  }

  /**
   * Event handler for Edit Category Settings action
   */
  async function onEditCategorySettingsHandler(): Promise<void> {
    await adminRouter.pushRoute<NodePageAndParams>(AdminPages.NodeSettingsPage, {
      nodeId: IdConverter.decodeId(tenant, category.id)
    });
  }

  const adminNodeActions: CategoryActionMenuItem[] = [
    {
      name: CategoryAction.MANAGE_CATEGORY_CONTENT,
      policy: (): boolean => getPolicyChecksForManageContent(),
      actionHandler: onManageCategoryContentHandler
    },
    {
      name: CategoryAction.EDIT_CATEGORY_SETTINGS,
      policy: (): boolean => checkPolicy(category.categoryPolicies.canAdminNode),
      actionHandler: onEditCategorySettingsHandler
    }
  ];

  /**
   * Function to check if the action item should be displayed
   *
   * @param policy A callback function to determine if action item should be displayed
   * @return boolean whether to display the action item
   * will render if no policy was provided.
   */
  function actionShouldDisplay(policy: () => boolean): boolean {
    if (policy) {
      return policy();
    }
    return true;
  }

  const hasAdminNodeItemsToDisplay: boolean =
    adminNodeActions?.filter(action => actionShouldDisplay(action?.policy)).length > 0;

  /**
   * Renders admin node menu items in Category Action Menu
   *
   * @param action The action to be displayed
   */
  function renderAdminNodeMenuItem(action: CategoryActionMenuItem): React.ReactElement {
    const displayAdminNodeMenuItem = actionShouldDisplay(action?.policy);
    const actionHandler = action?.actionHandler;

    if (displayAdminNodeMenuItem) {
      return (
        <Dropdown.Item
          key={action.name}
          onClick={actionHandler}
          data-testid={`CategoryActionMenuItem.${action.name}`}
        >
          {formatMessage(`${action.name}.category`)}
        </Dropdown.Item>
      );
    }
    return null;
  }

  /**
   * Renders the edit page link in Category Action Menu
   */
  function renderEditPageLink(): React.ReactElement {
    return pageEditorLink(
      <Dropdown.Item key={'editPage'} data-testid={'CategoryActionMenuItem.EditPage'}>
        {formatMessage(`editPage`)}
      </Dropdown.Item>
    );
  }

  return (
    (hasAdminNodeItemsToDisplay || (isPageEditable && canAccessPageBuilder)) && (
      <>
        <Dropdown className={cx('lia-g-ml-10')}>
          <Dropdown.Toggle
            id="lia-category-action-menu"
            as={Button}
            aria-label={formatMessage('toggleButtonLabel')}
            className={cx('lia-g-icon-btn')}
            variant={ButtonVariant.NO_VARIANT}
          >
            <Icon
              icon={Icons.GearIcon}
              size={IconSize.PX_20}
              color={IconColor.GRAY_900}
              testId="CategoryActionMenuItem.GearIcon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            aria-labelledby="lia-category-action-menu"
            className={cx('lia-dropdown-menu')}
            popperConfig={dropdownPopperConfig}
            renderOnMount
            data-testid="CategoryActionMenuItem.dropdown"
          >
            {hasAdminNodeItemsToDisplay &&
              adminNodeActions.map(action => renderAdminNodeMenuItem(action))}
            {isPageEditable && canAccessPageBuilder && renderEditPageLink()}
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  );
};

export default CategoryActionMenu;
